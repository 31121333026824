/*global $*/
/*eslint no-undef: "error"*/

export default class InputPayment {
    classBlock = 'input-payment--block'
    classBlockOpen = 'input-payment-open'
    classButton = 'input-payment--button'
    classItem = 'input-payment--item'
    className = 'input-payment--name'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classBlock}`).toggleClass(self.classBlockOpen)
        })

        $(`.${self.classItem}`).click(function () {
            let name = $(this).data('name')
            let objBlock = $(this).closest(`.${self.classBlock}`)

            objBlock.removeClass(self.classBlockOpen)
            objBlock.find(`.${self.className}`).text(name)
        })
    }
}
